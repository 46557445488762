<template>
  <div class="py-2 log_box">
    <div class="text-left text-gray-500 duration-500 path sm:mt-6 sm:mb-4">
      首页-个人中心-保修查询-物流查询
    </div>
    <div class="my-2 mt-6 text-left sm:text-base">
      物流信息
    </div>
    <div class="duration-500 sm:pb-6 message">
      <div class="text-left">物流公司：{{ this.type == 'merchant' ? this.orderInfo.platformCustom : orderInfo.company }}</div>
      <div class="text-left">物流编号：{{ this.type == 'merchant' ? this.orderInfo.kdNum : orderInfo.shipmentNumber }}</div>
      <div class="text-left duration-500 mes_bottom sm:mt-2">
        <span v-html="orderInfo.platformReceiving"></span>
      </div>
    </div>
    <div v-if="logisticsList.length > 0">
      <div class="duration-500 sm:mt-6 timeline">
        <el-timeline>
          <el-timeline-item :timestamp="item.ftime" placement="bottom" v-for="item in logisticsList" :key="item.ftime">
            <div class="flex justify-start duration-500">
              <div>
                <div class="text-left duration-500 line_content">
                  {{ item.context }}
                </div>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <el-empty :image-size="200" v-else></el-empty>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderInfo: {},
      logisticsList: [],
      type: ""
    };
  },
  created() {
    this.type = this.$route.query.type
    let orderInfo = localStorage.getItem("ORDER_INFO");
    if (orderInfo) {
      this.orderInfo = Object.assign({}, JSON.parse(orderInfo));
      console.log(this.orderInfo)
      this.getLogistics();
    }
  },
  methods: {
    // 获取物流
    getLogistics() {
      this.$request({
        method: "get",
        url: this.$requestPath.getExpress,
        params: {
          company: this.type == 'merchant' ? this.orderInfo.platformCustom : this.orderInfo.company,
          expressNo: this.type == 'merchant' ? this.orderInfo.kdNum : this.orderInfo.shipmentNumber,
        },
      })
        .then((res) => {
          console.log("获取的快递信息", res);
          if (res.code == 0) {
            this.logisticsList = [];
            this.logisticsList.push(...res.data);
          }
        })
        .catch((error) => {
          console.log("数据请求失败，请稍后再试", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.log_box {
  padding: 0 var(--margin-base);
  min-height: var(--min-height);
}

.message {
  border-bottom: 0.0625rem solid var(--border-base);
}

.ligth_text {
  color: rgb(128, 128, 128);
}

@media screen and (max-width: 640px) {
  .productImg {
    width: 5.625rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  .left_box {
    width: calc(100% - 5.625rem);
  }
}

@media screen and (min-width: 640px) {
  .productImg {
    width: 12.5rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  .left_box {
    width: calc(100% - 12.5rem);
  }
}
</style>
<style>
.el-timeline-item__timestamp {
  display: flex;
  justify-content: flex-start;
}

.el-timeline .el-timeline-item:nth-child(1) .el-timeline-item__node {
  background: red;
}

@media screen and (max-width: 640px) {
  .path {
    margin: 0.625rem 0;
  }

  .message {
    padding-bottom: 0.625rem;
    font-size: 0.8125rem;
  }

  .mes_bottom {
    margin-top: 0.375rem;
  }

  .timeline {
    margin-top: 1rem;
  }

  .line_content {
    font-size: 0.8125rem;
  }
}
</style>
